import React from 'react'
import './App.css'
import OtpDisplay from './OtpDisplay'

function App () {
  return (
    <div className="App">
      <header className="App-header">
        <OtpDisplay />
      </header>
    </div>
  )
}

export default App
